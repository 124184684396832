<template src="./AcademicAllCourses.html">
</template>

<script>
import coursesService from "@/services/Courses";
import eventsService from "@/services/Events";
import ArrobaMedellinHorizontalCard from '@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard';

export default {
  name: 'academic-all-courses',
  components: { ArrobaMedellinHorizontalCard },
  data () {
    return {
      typeCard: null,
      elements: []
    }
  },
  created() {
    this.getCoursesData();
  },
  methods: {
    async getCoursesData(){
      try {
        if (this.$route.query.district === 'academic') {
          this.typeCard = {button: "button--purple"};
          if (this.$route.query.idInstitution && this.$route.query.idPogram) {
            const {idInstitution, idPogram} = this.$route.query;
            const response = await coursesService.getCoursesByProgram(idPogram, idInstitution)
            this.elements = response[0].courses;
          } else if (this.$route.query.type === 'recommended') {
            this.elements = await coursesService.getRecommendedCourses()
          } else {
            this.elements = await coursesService.getMyCourses()
          }
        } else if (this.$route.query.district === 'events') {
          this.typeCard = {button: "button--green"};
          if (this.$route.query.type === 'myEvents') {
            eventsService.getMyEvents()
              .then(response => {
                this.elements = response.data;
              })
          } else if (this.$route.query.type === 'interest') {
            eventsService.getInterestingEvent()
              .then(response => {
                this.elements = response.data;
              })
          }
        }
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },
    openCourseUrl(url) {
      if(url.length) {
        window.open(url, '_blank')
      }
    }
  },
  computed: {
    cardType () {
      return this.$route.query.district;
    }
  }
}
</script>

<style lang="scss" scoped src="./AcademicAllCourses.scss">
</style>
